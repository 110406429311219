<template>
	<div class="admin-container">
		<Head />
		<div class="lab-content">
			<div class="container">
				<div class="lab-panel">
					<div class="lab-cell flex">
						<div class="lab-cell__hd flex_bd">
							<div class="lab-website flex flex-ac">
								<div class="lab-website__hd">{{$t('prtxt')}}：</div>
								<div class="lab-website__bd flex_bd">
									{{info.promotionPrefix}}{{info.promotionCode}}
								</div>
								<div class="lab-website__ft">
									<div class="lab-copy" v-clipboard:copy="info.promotionPrefix+info.promotionCode"
										v-clipboard:success="onCopy" v-clipboard:error="onError">
										{{$t('copy')}}
									</div>
								</div>
							</div>
							<div class="lab-data flex clearfix">
								<div class="lab-data__item flex_bd">
									<div class="lab-data__item__value">
										{{info.firstLevel}}
									</div>
									<div class="lab-data__item__label">
										{{$t('prtxt2')}}
									</div>
								</div>
								<div class="lab-data__item flex_bd">
									<div class="lab-data__item__value">
										{{info.secondLevel}}
									</div>
									<div class="lab-data__item__label">
										{{$t('prtxt3')}}
									</div>
								</div>
								<div class="lab-data__item flex_bd">
									<div class="lab-data__item__value">
										{{promotion.estimatedReward}}
									</div>
									<div class="lab-data__item__label">
										{{$t('prtxt4')}}
									</div>
								</div>
								<div class="lab-data__item flex_bd">
									<div class="lab-data__item__value">
										{{promotion.extraReward}}
									</div>
									<div class="lab-data__item__label">
										{{$t('prtxt5')}}
									</div>
								</div>
								<div class="lab-data__item flex_bd">
									<div class="lab-data__item__value">
										{{ info.partnerlevel }}
									</div>
									<div class="lab-data__item__label">
										{{$t('prtxt6')}}
									</div>
								</div>
							</div>
						</div>
						<div class="lab-cell__bd">
							<img src="../assets/photo/web/lab_code.png" alt="">
						</div>
					</div>
					<div class="lab-mask" v-if="isLogin == false">
						<ul class="login-links clearfix">
							<li>
								<router-link to="/login">
									{{$t('meun8')}}
								</router-link>
							</li>
							<li>
								<router-link to="/register">
									{{$t('meun9')}}
								</router-link>
							</li>
						</ul>
					</div>
				</div>
				<div class="lab-tip">*{{$t('prtip1')}}</div>
				<div class="lab-rule">
					<div class="lab-rule-title">{{$t('prtit')}}</div>
					<div class="lab-rule-desc">
						<div class="lab-rule-h4">
							{{$t('prdesc')}}
						</div>
						<ul>
							<li>{{$t('prli1')}}</li>
							<li>{{$t('prli2')}}</li>
							<li>{{$t('prli3')}}</li>
							<li>{{$t('prli4')}}</li>
							<li>{{$t('prli5')}}</li>
							<li>{{$t('prli6')}}</li>
							<li>{{$t('prli7')}}</li>
						</ul>
						<div class="lab-table">
							<el-table :data="tableData" style="width: 100%" border>
								<el-table-column prop="td1" :label="$t('prth1')" align="center" width="120">
									<template slot-scope="scope">
										<span>{{ scope.row.td1 }}</span>
										<span v-if="scope.row.td1 == 'L1'">({{$t('prlev1')}})</span>
										<span v-if="scope.row.td1 == 'L2'">({{$t('prlev2')}})</span>
										<span v-if="scope.row.td1 == 'L3'">({{$t('prlev3')}})</span>
										<span v-if="scope.row.td1 == 'L4'">({{$t('prlev4')}})</span>
										<span v-if="scope.row.td1 == 'L5'">({{$t('prlev5')}})</span>
										<span v-if="scope.row.td1 == 'L6'">({{$t('prlev6')}})</span>
									</template>
								</el-table-column>
								<el-table-column prop="td2" :label="$t('prth2')" align="center" width="170">
								</el-table-column>
								<el-table-column prop="td3" :label="$t('prth3')" align="center">
									<template slot-scope="scope">
										<span>{{ scope.row.td3 }}</span>/<span>{{ scope.row.time }}</span>
										<span v-if="scope.row.td1 == 'L6'">{{$t('prtd2')}}</span>
										<span v-else>{{$t('prtd1')}}</span>
									</template>
								</el-table-column>
								<el-table-column prop="td4" :label="$t('prth4')" align="center">
									<template slot-scope="scope">
										<span>{{ scope.row.td4 }}</span>/<span>{{ scope.row.time }}</span>
										<span v-if="scope.row.td1 == 'L6'">{{$t('prtd2')}}</span>
										<span v-else>{{$t('prtd1')}}</span>
									</template>
								</el-table-column>
								<el-table-column prop="td5" :label="$t('prth5')" align="center" width="130">
								</el-table-column>
								<template slot="empty">
									<p>{{$t('czEmpty')}}</p>
								</template>
							</el-table>
							<div class="lab-example">
								<h3>{{$t('prtit2')}}：</h3>
								<p>{{$t('prtxt7')}}</p>
								<p>{{$t('prtxt7')}}</p>
							</div>
						</div>
						<ul>
							<li>{{$t('prli8')}}</li>
							<li>{{$t('prli9')}}</li>
							<li>{{$t('prli10')}}</li>
							<li>{{$t('prli11')}}</li>
						</ul>
					</div>
				</div>
				<div class="lab-tool">
					<div class="lab-tool-hd">
						<div class="lab-tool-title">{{$t('prtit3')}}</div>
					</div>
					<div class="lab-tool-bd">
						<ul class="tool-list clearfix">
							<li @click="drawer = true">
								<div class="tool-pic">
									<img src="https://al-cex-pub.s3.ap-southeast-1.amazonaws.com/2019/08/08/promotioncard.png"
										alt="">
								</div>
								<div class="tool-content">
									<div class="tool-title">{{$t('pt_title')}}<span>{{$t('pt_card_title_tips')}}</span>
									</div>
									<div class="tool-desc">{{$t('pt_desc')}}</div>
								</div>
							</li>
							<li @click="drawer2 = true">
								<div class="tool-pic">
									<img src="https://al-cex-pub.s3.ap-southeast-1.amazonaws.com/2019/08/08/invitebg.jpg"
										alt="">
								</div>
								<div class="tool-content">
									<div class="tool-title">{{$t('pt_title')}}</div>
									<div class="tool-desc">{{$t('pt_invite_desc')}}</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<!-- 素材 -->
				<el-drawer :title="$t('pt_title')" :visible.sync="drawer" :direction="direction" size="350px">
					<div class="material-content">
						<div class="material-pic">
							<img src="https://al-cex-pub.s3.ap-southeast-1.amazonaws.com/2019/08/08/promotioncard.png"
								alt="">
						</div>
						<div class="material-title">
							{{$t('pt_title')}}
						</div>
						<div class="material-desc">
							<p>{{$t('pt_card_amount')}}：0.001 BTC</p>
							<p>{{$t('pt_card_deadline')}}：60{{$t('pt_card_day')}}</p>
						</div>
						<div class="material-btns">
							<el-button type="danger" v-if="isLogin && !hasCard" @click="getFreeCard">
								{{$t('pt_card_btn')}}
							</el-button>
							<el-button type="danger" v-if="isLogin && hasCard" @click="promotionClick">
								{{$t('usepromotion')}}
							</el-button>
							<el-button type="success" v-if="!isLogin" @click="ptLogin">{{$t('pt_card_btn_login')}}</el-button>
						</div>
						<div class="material-rule">
							<div class="material-rule__head">
								<div class="material-rule__title">
									{{$t('pt_card_rule')}}
								</div>
							</div>
							<div class="material-rule__summary">
								{{$t('pt_card_summary')}}
							</div>
							<div class="material-rule__desc">
								<p>{{$t('pt_card_rule1')}}</p>
								<p>{{$t('pt_card_rule2')}}</p>
								<p>{{$t('pt_card_rule3')}}</p>
								<p>{{$t('pt_card_rule4')}}</p>
								<p>{{$t('pt_card_rule5')}}</p>
								<p>{{$t('pt_card_rule6')}}</p>
							</div>
						</div>
					</div>
				</el-drawer>
				<!-- 素材2 -->
				<el-drawer :title="$t('context_title3')" :visible.sync="drawer2" :direction="direction" size="350px">
					<div class="tg-content">
						<div class="tg-pic" id="promotionImage">
							<img src="../assets/photo/web/invitebg1.jpg" alt="">
							<div class="tg-pic-text2">
								<vue-qr :text="info.promotionPrefix+info.promotionCode" :margin="8" :size='100'>
								</vue-qr>
								<p style="margin-top: 12px;">{{$t('context_title1')}}</p>
								<p class="tg-pic-code2">{{$t('context_title2')}}{{info.promotionCode}}</p>
							</div>
						</div>
						<div class="tg-foot">
							<div>{{$t('imagetips')}}</div>
							<el-button @click="save">{{$t('saveimage')}}</el-button>
							<a id="link"></a>
						</div>
					</div>
				</el-drawer>
				<!-- 素材3 -->
				<el-drawer :title="promotionTitle" :visible.sync="drawer3" :direction="direction" size="350px">
					<div class="tg-content">
						<div class="tg-pic" id="promotionImage2">
							<img src="../assets/photo/web/promotionbg1.jpg" alt="">
							<div class="tg-pic-text">
								<p class="tg-pic-code">{{promotionCode}}</p>
								<p>{{$t('context_title')}}</p>
							</div>
						</div>
						<div class="tg-foot">
							<div>{{$t('imagetips')}}</div>
							<el-button @click="save2">{{$t('saveimage')}}</el-button>
							<a id="link2"></a>
						</div>
					</div>
				</el-drawer>
				<div class="lab-rank">
					<el-row>
						<el-col :xs="24" :sm="12">
							<div class="lab-rank-item">
								<div class="lab-rank-item__hd">
									<div class="lab-rank-item__title">
										{{$t('prtit4')}}
									</div>
									<div class="lab-rank-item__time">
										{{$t('prtxt9')}}2023-12-27 02：00：00
									</div>
								</div>
								<div class="lab-rank-item__bd">
									<el-table :data="tableData2" style="width: 100%">
										<el-table-column type="index" :label="$t('prth6')" width="65" align="center">
										</el-table-column>
										<el-table-column prop="userIdentify" :label="$t('prth7')" align="center">
										</el-table-column>
										<el-table-column prop="levelOne" :label="$t('prth8')" align="center">
										</el-table-column>
										<el-table-column prop="estimatedReward" :label="$t('prth9')" width="130"
											align="center">
										</el-table-column>
										<el-table-column prop="extraReward" :label="$t('prth10')" align="center">
										</el-table-column>
										<template slot="empty">
											<p>{{$t('czEmpty')}}</p>
										</template>
									</el-table>
									<div class="lab-rank-item__tip">*{{$t('prtip2')}}</div>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="12">
							<div class="lab-rank-item">
								<div class="lab-rank-item__hd">
									<div class="lab-rank-item__title">
										{{$t('prtit5')}}
									</div>
									<div class="lab-rank-item__time">
										{{$t('prtxt9')}}2023-12-27 02：00：00
									</div>
								</div>
								<div class="lab-rank-item__bd">
									<el-table :data="tableData3" style="width: 100%">
										<el-table-column type="index" :label="$t('prth6')" width="65" align="center">
										</el-table-column>
										<el-table-column prop="userIdentify" :label="$t('prth7')" align="center">
										</el-table-column>
										<el-table-column prop="levelOne" :label="$t('prth8')" align="center">
										</el-table-column>
										<el-table-column prop="estimatedReward" :label="$t('prth9')" width="130"
											align="center">
										</el-table-column>
										<el-table-column prop="extraReward" :label="$t('prth10')" align="center">
										</el-table-column>
										<template slot="empty">
											<p>{{$t('czEmpty')}}</p>
										</template>
									</el-table>
									<div class="lab-rank-item__tip">*{{$t('prtip3')}}</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		myInfo,
		myPromotion,
		topRank,
		promotionGetFreecard,
		promotionCard
	} from '@/api/api/user'
	import html2canvas from 'html2canvas'
	import vueQr from 'vue-qr'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot,
			vueQr
		},
		data() {
			return {
				tableData: [{
					td1: 'L1',
					td2: '0≤N≤10',
					td3: '20%',
					td4: '5%',
					time: 6,
					td5: ''
				}, {
					td1: 'L2',
					td2: '10<N≤100',
					td3: '30%',
					td4: '10%',
					time: 12,
					td5: ''
				}, {
					td1: 'L3',
					td2: '100<N≤500',
					td3: '40%',
					td4: '15%',
					time: 12,
					td5: ''
				}, {
					td1: 'L4',
					td2: '500<N≤1500',
					td3: '50%',
					td4: '20%',
					time: 24,
					td5: '+5%'
				}, {
					td1: 'L5',
					td2: '1500<N≤3000',
					td3: '60%',
					td4: '25%',
					time: 24,
					td5: '+10%'
				}, {
					td1: 'L6',
					td2: '3000<N',
					td3: '60%',
					td4: '25%',
					time: null,
					td5: '+15%'
				}],
				tableData2: [],
				tableData3: [],
				// isLogin: false,
				hasCard: false,
				info: {
					partnerlevel: '-',
				},
				promotion: {

				},
				promotionTitle: "",
				promotionCode: '',
				drawer: false,
				drawer2: false,
				drawer3: false,
				direction: 'rtl',
				// info: {}
			};
		},
    computed: {
      isLogin: function() {
        return this.$store.getters.isLogin;
      },

    },

		mounted() {
			this.init()
		},
		methods: {
			init() {
				if (this.isLogin) {
					this.getMemberInfo()
					this.getCardInfo()
				}
				this.getRank()
			},
			getMemberInfo() {
				myInfo().then(res => {
					if (res.code == 0) {
						this.info = res.data
						this.info.partnerlevel = this.partnerNameByCount(res.data.firstLevel)
					}
				})
				myPromotion().then(res => {
					if (res.code == 0) {
						// this.hasCard = true
					}
				})
			},
			getCardInfo() {
				promotionCard().then(res => {
					if (res.code == 0) {
						if (res.data != null && res.data.length > 0) {
							this.promotionTitle = res.data[0].cardName
							this.promotionCode = res.data[0].cardNo
							this.hasCard = true
						}
					}
				})
			},
			getRank() {
				topRank().then(res => {
					if (res.code == 0) {
						this.tableData2 = res.data.topreward
						this.tableData3 = res.data.topinvite
					}
				})
			},
			partnerNameByCount: function(count) {
				if (count == undefined || count == null || count == '') {
					return '-'
				}
				let partnerName = 'Lv1(' + this.$t('prlev1') + ')'
				if (count > 10) partnerName = 'Lv2(' + this.$t('prlev2') + ')'
				if (count > 100) partnerName = 'Lv3(' + this.$t('prlev3') + ')'
				if (count > 500) partnerName = 'Lv4(' + this.$t('prlev4') + ')'
				if (count > 1500) partnerName = 'Lv5(' + this.$t('prlev5') + ')'
				if (count > 3000) partnerName = 'Lv6(' + this.$t('prlev6') + ')'
				return partnerName
			},
			onCopy() {
				this.$message.success(this.$t('copysuccess'))
			},
			// 复制失败时的回调函数
			onError() {
				this.$message.error(this.$t('copyerr'))
			},
			save() {
				html2canvas(document.querySelector("#promotionImage")).then(canvas => {
					var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
					console.log(image)
					// here is the most important part because if you dont replace you will get a DOM 18 exception.
					//save as download without name and extension
					//window.location.href = image;
					var link = document.getElementById('link');
					link.setAttribute('download', this.$t('context_title3')+'.png');
					link.setAttribute('href', canvas.toDataURL("image/png").replace("image/png",
						"image/octet-stream"));
					link.click();
				})
			},
			save2() {
				html2canvas(document.querySelector("#promotionImage2")).then(canvas => {
					var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
					console.log(image)
					// here is the most important part because if you dont replace you will get a DOM 18 exception.
					//save as download without name and extension
					//window.location.href = image;
					var link = document.getElementById('link2');
					link.setAttribute('download', this.promotionTitle+'.png');
					link.setAttribute('href', canvas.toDataURL("image/png").replace("image/png",
						"image/octet-stream"));
					link.click();
				})
			},
			ptLogin() {
				this.$router.push({
					path: '/login'
				})
			},
			promotionClick() {
				if (!this.isLogin) {
					this.$message.error(this.$t('loginFirst'))
					return
				}
				this.drawer3 = true
			},
			getFreeCard() {
				promotionGetFreecard().then(resp => {
					if (resp.code == 0) {
						// this.$notify.success({
						// 	title: this.$t('apiTxt2'),
						// 	message: this.$t('pt_card_receivew_success'),
						// 	duration: 30,
						// })
            this.$message.success(rthis.$t('pt_card_receivew_success'))
						this.getCardInfo()
					} else {

            this.$message.error(resp.message)
						// this.$notify.error({
						// 	title: this.$t('apiTxt2'),
						// 	message: resp.message,
						// 	duration: 15,
						// })
					}
				})
			},
		}
	}
</script>
